import React, { useEffect } from 'react';
import { appMeta, Routes } from '@explorer/src';
import { PageHeadWrapper } from '@explorer/containers';
import { ErrorPage } from '@explorer/components/shared';

/**
 * Page Route
 */
const route: Routes = '/404';

/**
 * Page Meta
 */
const pageMeta: PageMeta = appMeta[route];

/**
 * Page Component (use an UniqueName to improve Hot Reload performance)
 */
const PageError = () => {
  return (
    <PageHeadWrapper pageMeta={pageMeta}>
      <ErrorPage error="page-not-found" />
    </PageHeadWrapper>
  );
};

export default PageError;
